import API from "api";
import { WithdrawalStatusENUM } from "api/withdrawalGroup";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import FileSaver from "file-saver";
import { Button, DateRangePicker, Modal, Select, useTranslation } from "pay-kit";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { rejectSettlement } from "utils/filterSettlement";

import { StatusesContext } from "../../../contexts/statusesContext";
import styles from "./DownloadReportModal.module.scss";
import { blobToParsedJSON } from "./utils";
import { notLaterThanToday } from "utils/date";

interface IDownloadReportModal {
	readonly isOpen: boolean;
	readonly onClose: () => void;
}

const DownloadReportModal: React.FC<IDownloadReportModal> = ({ isOpen, onClose }) => {
	const { t } = useTranslation();
	const [payment_system, setPaymentSystem] = useState<string | undefined>();
	const [date, setDate] = useState<{ readonly from: string; readonly to: string } | undefined>();
	const [status, setStatus] = useState<WithdrawalStatusENUM | undefined>();
	const [errors, setErrors] = useState<any>(null);
	const statusesContext = useContext(StatusesContext);
	const walletTypesContextAPI = useContext(WalletTypesContext);

	const canSubmit = date !== undefined && date.from !== undefined && date.to !== undefined;

	useEffect(() => {
		if (!isOpen) {
			setPaymentSystem(undefined);
			setDate(undefined);
			setStatus(undefined);
		}
	}, [isOpen]);

	const getReport = () => {
		if (!canSubmit) return;

		setErrors(null);

		API.withdrawal
			.getReport({
				payment_system,
				status,
				date_from: date.from,
				date_to: date.to,
			})
			.then(async (blob: unknown) => {
				const json = await blobToParsedJSON(blob as Blob);

				if (json.status === "success") {
					const newBlob = new Blob([blob as Blob], {
						type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					});

					const psName = payment_system ? `_${payment_system}` : "";

					FileSaver.saveAs(newBlob, `manual_withdrawal${psName}_${date.from}-${date.to}.xlsx`);

					window.pushAlert({
						// description: `Заявки на ручные выплаты выгружены`,
						description: t("Manual withdrawals requests downloaded"),
						type: "success",
					});
				} else {
					if (json?.message) {
						setErrors(json?.message || undefined);
					}

					window.pushAlert({
						// description: `Произошла ошибка при выгрузке отчета`,
						description: t("Failed to download report"),
						type: "error",
					});
				}

				return blob;
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const statusOptions = useMemo(() => {
		let options = [
			{
				value: undefined,
				label: t("All"),
			},
		];

		if (statusesContext.list.length > 0) {
			options = [
				...options,
				...statusesContext.list
					.filter((status) => [WithdrawalStatusENUM.NEW, WithdrawalStatusENUM.ERROR].includes(status.status))
					.map((s) => ({ value: s.status as any, label: s.name })),
			];
		}

		return options;
	}, [statusesContext.list.length]);

	return (
		<Modal isOpen={isOpen} title={t("Download manual withdrawal requests")} onClose={onClose}>
			<div className={styles.form}>
				<Select
					label={t("Wallet type")}
					options={[
						{
							value: undefined,
							label: t("All"),
						},
						...rejectSettlement(walletTypesContextAPI.walletTypes).map((ps) => ({ value: ps.code, label: ps.name })),
					]}
					onChange={(v) => setPaymentSystem(v as string)}
					value={payment_system}
					isLoading={walletTypesContextAPI.isLoading}
					data-test-id="wallet-type"
					name="wallet-type"
				/>
				<Select
					value={status}
					onChange={(v) => setStatus(v as WithdrawalStatusENUM)}
					label={t("Status")}
					isLoading={statusesContext.isLoading}
					options={statusOptions}
					data-test-id="status"
					name="status"
				/>

				<div className={styles.dateRangePicker}>
					<div className={styles.label}>
						{t("Period")}
						<span className={styles.redStar}>*</span>
					</div>
					<DateRangePicker
						dateFormat="YYYY-MM-DD"
						onChange={(v) => setDate(v)}
						value={date}
						blockPredicate={notLaterThanToday}
						data-test-id=""
						fromPlaceholder={t("From")}
						toPlaceholder={t("To")}
					/>
				</div>

				{errors !== null && <div className={styles.errors}>{JSON.stringify(errors)}</div>}

				<div className={styles.actions}>
					<Button disabled={!canSubmit} data-test-id="" onClick={getReport}>
						{t("Download")}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default DownloadReportModal;
